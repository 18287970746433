import { Button,Grid,Input, Popup, Container, Image } from "semantic-ui-react";
import styled from "@emotion/styled";
import theme from "@styles/theme";
import { H6, Paragraph2 } from "@styles/Global.styles";
import React from "react";
import mq from '@styles/mq';

export const IconButton = styled(Button)`
    background: transparent !important;
`
export const CartHeadingRow = styled(Grid.Row)`
`
export const PlateDetailSection = styled.div`
`

export const PlateDetailSectionHeading = styled.div`
    display: block;
    clear: both;    
    margin-bottom: 4px;
    margin-top: 6px;
`
export const PlateImageContainer = styled.div`
    padding-bottom: 8px;
    width: 100%;
    flex-grow: 0 !important;
`
export const HeadingRangeName = styled(H6)`
    display: inline-block;
`

export const PlateDetailSectionSize = styled(H6)`
    float: right;
    margin: 0 !important;
`

export const PlateDetailsGroup = styled.div`
    display: block;
    clear: both;
    margin-bottom: 5px;
`

export const PlateDetailsGroupLabel = styled(({darkTheme, ...rest}) => <div {...rest} />)`
    display: inline-block;
    color: ${p => p.darkTheme ? '#eee' : '#707070'};
`

export const PlateDetailsGroupField = styled(({darkTheme, ...rest}) => <div {...rest} />)`
    float: right;
    color: ${p => p.darkTheme ? '#eee' : '#707070'};
`
export const PlateImageSectionGridColumn = styled(Grid.Column)`
    padding-bottom: 20px;
    justify-content: space-between;
    ${mq({
        paddingRight: ['1em;','1em;','75px !important']
    })}
     > div {
         flex-grow: 0 !important;
     }
`

export const PlateImageSectionHeading = styled.div`
    display: block;
    clear: both;
    margin-bottom: 8px;
    margin-top: 20px;
    width: 100%;
    flex-grow: 0 !important;
`
export const CartListGrid = styled(Grid)`
`
export const ShoppingCartWrapper = styled(({darkTheme, ...rest}) => <div {...rest} />)`
    background-color: ${p => p.darkTheme ? 'black' : '#F9F9F9'} ;
    color: ${p => p.darkTheme ? 'white' : 'black'} ;
    padding: 20px 0 40px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 93%;
`
export const CartLineItemRow = styled(({darkTheme, ...rest}) => <Grid.Row {...rest} />)`
    &:before {
        position: absolute;
        content: "";
        top: 0em;
        left: 0px;
        width: calc(100% - 2rem);
        height: 4px;
        margin: 0% 1rem;
        background-color: ${p => p.darkTheme ? 'white' : '#000'} ;
    }

    &:last-child{
        border-bottom: none;
    }
`
export const CombinationNumber = styled.div`
    
    font-size: 18px;
    line-height: 26px;
    font-weight: bold;
    padding-top: 20px;
    text-align: left;
`
export const PlateItemPriceComputer =  styled(({available, ...others})=><span {...others}/>)`
    text-decoration: ${props=>props.available ? "none" : "line-through"};
`
export const PlateItemPrice = styled(({available, ...others})=><div {...others}/>)`
    text-decoration: ${props=>props.available ? "none" : "line-through"};
    font-size: 18px;
    font-weight: bold;
    line-height: 26px;
    padding-top: 20px;
`
export const PlateItemButtonGroup = styled(Button.Group)`
    padding-top: 10px;
`
export const CartHeadingDividerRow = styled(Grid.Row)`
    border-bottom: 4px solid ${theme.brand.colors.black};
    padding: 0 !important;
`

export const CartSummaryGridSection = styled(({darkTheme, ...rest}) => <Grid {...rest} />)`
    > .row {
        &:first-of-type {
            padding-top: 30px !important;

            &:before {
                position: absolute;
                content: "";
                top: 0em;
                left: 0px;
                width: calc(100% - 2rem);
                height: 4px;
                margin: 0% 1rem;
                background-color: ${p => p.darkTheme ? 'white' : '#000000'} ;
            }
        }
    }
`

export const PromotionWrapper = styled.div`
    padding-bottom: 20px;
`
export const PromotionCodes = styled.div`

    & > a{
        padding: 10px 8px !important;
        margin-top: 10px !important;
    }
`
export const PromoInput = styled(Input)`
    height: 50px;
    width: 315px;
    margin-bottom: 5px;
    font-size: 16px !important;
    & > input{
        text-transform: uppercase;
        padding: 14px 10px 12px 10px !important;
        line-height: 24px !important;
    }
`
export const PromotionLabel = styled(({darkTheme, ...rest}) => <div {...rest} />)`
    font-size: 13px;
    line-height: 19px;
    font-weight: bold;
    padding: 5px 0 5px 0;
    ${p => p.darkTheme ? '#eee' : '#707070'};
`

export const PromotionField = styled.div`
`
export const SummaryActionGrid = styled(Grid)`
    padding-top: 15px !important;
`
export const SummaryActionRow = styled(({darkTheme, ...rest}) => <Grid.Row {...rest} />)`	
    padding: 2px 0 2px 0 !important;
    color: ${p => p.darkTheme ? 'white' : 'black'}	
`	
export const SummaryActionColumn = styled(Grid.Column)`
    padding: 9px !important;
    color: ${theme.brand.colors.darkGrey};
    font-size: 18px;
    line-height: 26px;
`
export const SummaryTotal = styled(({darkTheme, ...rest}) => <Paragraph2 {...rest} />)`
    font-weight: bold;
    color: ${p => p.darkTheme ? theme.brand.colors.white : theme.brand.colors.black}
`
export const CartItemDeleteButton = styled.div`
    padding: 8px;
    cursor: pointer;
`
export const PopupContainer = styled.div`
    padding: 12px 20px 6px 20px;
    width: 350px;
`
export const PopupHeading = styled.div`
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 2px solid ${theme.brand.colors.lightGrey};
`
export const PopupContent = styled.div`
    padding: 20px 0 15px 0;
`
export const DeletePopup = styled(Popup)`
    padding: 0 !important;
    &:before {
        left: auto !important;
        right: 35px !important;
    }

    .button {
        min-width: 50px !important;
    }
`
export const DisableLaybyHelpInfo = styled.div`
    position: absolute;
    top: 20px;
    right: 35px;
`
export const PurchaseTypeName = styled.div`

`
export const ShoppingCartItemImageSection = styled(Grid.Row)`
    -webkit-filter: blur(3px);
    -moz-filter: blur(3px);
    -o-filter: blur(3px);
    -ms-filter: blur(3px);
    filter: blur(3px);
    background-color: #000;
    color: white;
`
export const UnavailableWrapper = styled.div`
    text-align: center;
    display: flex;
    width: 100%;
    position: absolute;
    z-index: 999;
    color: red;
    height: 100%;
    justify-content: center;
    align-items: center;
`
export const UnavailableMessage = styled.div`
    padding: 20px;
    background-color: rgba(255,255,255,0.9);
    border: 1px solid red;
`
export const EmptyCartContainer = styled(Container)`
    text-align:center;
`
export const EmptyCartImg = styled(Image)`
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 40%;
`
